<template>
  <GoogleMapLoader :mapConfig="mapConfig">
    <template slot-scope="{ google, map }">
      <GoogleMapMarker
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
      />
    </template>
  </GoogleMapLoader>
</template>

<script>
import { mapSettings } from "@/components/dashboard/map/constants/mapSettings";
import GoogleMapLoader from '@/components/dashboard/map/GoogleMapLoader'
import GoogleMapMarker from '@/components/dashboard/map/GoogleMapMarker'

export default {
  name:"mapView",
  components: {
    GoogleMapLoader,
    GoogleMapMarker
  },

  data() {
    return {
      markers: [
        {
          id: "a",
          position: { lat: 41.015137, lng: 28.979530 },
        },
        {
          id: "b",
          position: { lat: 39.925533, lng: 32.866287 },
        },
        {
          id: "c",
          position: { lat: 38.734802, lng: 35.467987 },
        },
      ],
    };
  },

   computed: {
    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter,
      };
    },
    mapCenter() {
      return this.markers[1].position;
    },
  },
};
</script>
