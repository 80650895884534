<template>
  <div>
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";

export default {
  name: "GoogleMapLoader",
  props: {
    mapConfig: Object,
  },

  data() {
    return {
      google: null,
      map: null,
      apiKey: "AIzaSyDESVu6CyML0gkZ7jObnt7ki68a9XQOv5Q",
      mapsApiLoaded: false,
    };
  },

  async created() {
   await GoogleMapsApiLoader({
      apiKey: this.apiKey,
    }).then((google) => {
      this.google = google;
      this.initializeMap();
    });
  },

  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
    },
  },
};
</script>

<style scoped>
.google-map {
  width: 100%;
  min-height: 100%;
}
</style>
